
<template>
  <div>
    <associatedOption
      :fieldAttributes="fieldAttributes"
      type="select"
      :field="field"
      :value="value"
      v-on="$listeners"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";

export default {
  components: {
    associatedOption,
  },
  created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  props: ["field", "fieldAttributes", "result","value"],
};
</script>
